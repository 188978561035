<template>
  <el-drawer
    :visible.sync="visible"
    direction="ttb"
    :before-close="() => $emit('close')"
    :with-header="false"
    size="100%"
  >
    <div style="margin: 20px 0;">
      <el-button type="primary" @click="$emit('close')">返回</el-button>
    </div>
    <div class="section-title-row">
      <div class="title">基本信息</div>
      <div class="line"></div>
    </div>
    <el-row :gutter="25">
      <el-col :span="16">
        <el-form-item label="工地名称" prop="siteId">
          <el-select disabled v-model="dataForm.siteId">
            <el-option v-for="b in siteList" :key="b.id" :label="b.siteName" :value="b.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="施工部分" prop="constructionPart">
          <el-input disabled v-model="dataForm.constructionPart"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="6">
        <el-form-item label="工地地址" prop="siteAddress">
          <el-input disabled v-model="dataForm.siteAddress"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="所属基地" prop="baseName">
          <el-input disabled v-model="dataForm.baseName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="执行基地" prop="executeBaseId">
          <el-select disabled v-model="dataForm.executeBaseId">
            <el-option v-for="b in baseList" :key="b.id" :label="b.name" :value="b.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="负责人" prop="siteHeadId">
          <el-select disabled v-model="dataForm.siteHeadId">
            <el-option v-for="b in siteHeadList" :key="b.id" :label="b.userName" :value="b.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="6">
        <el-form-item label-width="82px" label="负责人电话" prop="siteHeadPhone">
          <el-input disabled v-model="dataForm.siteHeadPhone"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label-width="82px" label="项目部电话" prop="departmentPhone">
          <el-input disabled v-model="dataForm.departmentPhone"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="施工单位" prop="constructionUnit">
          <el-input disabled v-model="dataForm.constructionUnit"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="拌台" prop="mixingTable">
          <el-input disabled v-model="dataForm.mixingTable"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="6">
        <el-form-item label="施工时间" prop="dispatchTime">
          <el-date-picker
            disabled
            type="datetime"
            v-model="dataForm.dispatchTime"
            value-format="yyyy-MM-dd h:m:s"
            default-time="12:00:00"
          ></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="级配" prop="gradation">
          <el-input disabled v-model="dataForm.gradation"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="方量数量" prop="quantityNumber">
          <el-input disabled v-model="dataForm.quantityNumber"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="二级调度" prop="dispatcherUserId">
          <el-select disabled v-model="dataForm.dispatcherUserId">
            <el-option
              v-for="c in dispatcherUserList"
              :key="c.id"
              :label="c.nickName"
              :value="c.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <div class="section-title-row">
      <div class="title">车辆查找</div>
      <div class="line"></div>
    </div>
    <el-row>
      <el-form :model="searchForm" inline>
        <el-form-item label="需要车辆类型">
          <el-select v-model="searchForm.carSize">
            <el-option v-for="s in carSizeList" :key="s" :label="s" :value="s"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车辆自编号">
          <el-input v-model="searchForm.number"></el-input>
        </el-form-item>
        <el-button @click="search">查询</el-button>
      </el-form>
    </el-row>
    <el-table
      v-if="tableData"
      :data="tableData"
      stripe
      size="mini"
      :border="true"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        v-for="h in headers"
        :key="h.id"
        :label="h.label"
        :prop="h.prop"
        :width="h.width"
        :min-width="h.minWidth"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column header-align="center" align="center" label="昨日任务" prop="yesterdayList"></el-table-column>
      <el-table-column header-align="center" align="center" label="今日任务" prop="todayList"></el-table-column>
      <el-table-column header-align="center" align="center" label="明日任务" prop="tomorrowList"></el-table-column>
    </el-table>
  </el-drawer>
</template>
<script>

export default {
  name: 'send-vehicle',

  data () {
    return {
      searchForm: {
        carSize: '',
        number: ''
      },
      tableData: [],
      headers: [
        { id: 1, label: '车辆类别', prop: 'carSize', width: 100 },
        { id: 2, label: '自编号', prop: 'number', width: 150 }
      ]
    }
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    dataForm: {
      type: Object
    },
    siteList: {
      type: Array
    },
    baseList: {
      type: Array
    },
    siteHeadList: {
      type: Array
    },
    dispatcherUserList: {
      type: Array
    },
    carSizeList: {
      type: Array
    },
    demandCarSize: String
  },

  watch: {
    demandCarSize (v) {
      this.searchForm.carSize = v
    },

    visible (v) {
      if (v) {
        this.tableData = []
        this.searchForm.number = ''
      }
    }
  },

  methods: {
    async search () {
      const data = await this.$http({
        url: this.$http.adornUrl('/dispatch/task/list/dispatchTime'),
        method: 'post',
        data: this.searchForm
      })
      this.tableData = data.datas
    },

    handleSelectionChange (selection) {
      if (!selection.length) return
      this.$emit('select', selection[0])
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep th .el-checkbox {
  display: none;
}
</style>
